dmx.Actions({

    'subflow': function(options) {
        var subflow = this.parse(options.flow);
        var param = this.parse(options.param);
        
        return this.parse(subflow + '.runSub(' + JSON.stringify(param) + ')');
    },

    'comment': function(options) {
        if (dmx.debug) {
            console.debug(options.message);
        }
    },

    'wait': function(options) {
        var delay = this.parse(options.delay);

        if (typeof delay != 'number') {
            throw new Error('wait: Invalid delay');
        }

        return new Promise(function(resolve) {
            setTimeout(resolve, delay);
        });
    },

    'now': function(options) {
        return (new Date()).toISOString();
    },

    'random': function(options) {
        var lower = this.parse(options.lower);
        var upper = this.parse(options.upper);
        var floating = !!this.parse(options.floating);

        if (typeof lower != 'number' || !isFinite(lower)) {
            lower = 0;
        }

        if (typeof upper != 'number' || !isFinite(upper)) {
            upper = 1;
        }

        var rnd = lower + (Math.random() * (upper - lower));

        if (!floating && Math.floor(lower) == lower && Math.floor(upper) == upper) {
            rnd = Math.round(rnd);
        }

        return rnd;
    },

    'confirm': function(options) {
        var message = this.parse(options.message);

        if (typeof message != 'string') {
            throw new Error('confirm: Invalid message');
        }

        var result = confirm(message);

        if (result) {
            if (options.then) {
                return this._exec(options.then).then(function() {
                    return result;
                });
            }
        } else if (options.else) {
            return this._exec(options.else).then(function() {
                return result;
            });
        }

        return result;
    },

    'prompt': function(options) {
        var message = this.parse(options.message);

        if (typeof message != 'string') {
            throw new Error('prompt: Invalid message');
        }

        return prompt(message);
    },

    'alert': function(options) {
        var message = this.parse(options.message);

        if (typeof message != 'string') {
            throw new Error('alert: Invalid message');
        }

        return alert(message);
    },

    'repeat': function(options) {
        var items = dmx.clone(this.parse(options.repeat));

        if (!items) return;

        if (typeof items == 'boolean') {
            items = items ? [0] : [];
        } else if (typeof items == 'string') {
            items = items.split(/\s*,\s*/);
        } else if (typeof items == 'number') {
            var arr = [];
            for (var i = 0; i < items; i++) {
                arr.push(i + 1);
            }
            items = arr;
        }

        if (typeof items != 'object') {
            throw new Error('repeat: data is not repeatable');
        }

        var self = this;
        var parentScope = this.scope;
        var parentOutput = this.output;
        return this._each(items, function(value, index) {
            self.scope = new dmx.DataScope(Object.assign({
                $value: value,
                $index: index,
                $name: index,
                $key: index,
                $number: index + 1,
                $oddeven: index % 2
            }, value), parentScope);

            self.output = {};

            if (Array.isArray(options.outputFields) && value instanceof Object) {
                options.outputFields.forEach(function(field) {
                    self.output[field] = value[field];
                });
            }

            return self._exec(options.exec).then(function() {
                var output = self.output;
                self.scope = parentScope;
                self.output = parentOutput;
                return output
            });
        });
    },

    'condition': function(options) {
        var result = !!this.parse(options.if);

        if (result) {
            if (options.then) {
                return this._exec(options.then).then(function() {
                    return result;
                });
            }
        } else if (options.else) {
            return this._exec(options.else).then(function() {
                return result;
            });
        }

        return result;
    },

    'conditions': function(options) {
        if (Array.isArray(options.conditions)) {
            for (var i = 0; i < options.conditions.length; i++) {
                var condition = options.conditions[i];

                if (this.parse(condition.when)) {
                    return this._exec(condition.then);
                }
            }
        }
    },

    'select': function(options) {
        var expression = this.parse(options.expression);

        if (Array.isArray(options.cases)) {
            for (var i = 0; i < options.cases.length; i++) {
                var item = options.cases[i];

                if (this.parse(item.value) == expression) {
                    return this._exec(item.exec);
                }
            }
        }
    },

    'group': function(options) {
        if (options.name) {
            var self = this;
            var parentOutput = this.output;
            this.output = {};

            return this._exec(options.exec).then(function() {
                var output = self.output;
                self.output = parentOutput;
                return output;
            });
        }
            
        return this._exec(options.exec)
    },

    'while': function(options) {
        var self = this;
        var loop = function() {
            return new Promise(function(resolve) {
                if (!self.parse(options.condition)) return resolve();
                self._exec(options.exec).then(loop).then(resolve);
            });
        };

        return loop();
    },

    'switch': function(options) {
        /*
            {
                switch: {
                    expression: "{{myVar}}",
                    cases: [
                        { case: 1, exec: { console.log: { message: "Case 1" }}}
                        { case: 2, exec: { console.log: { message: "Case 2" }}}
                        { case: 3, exec: { console.log: { message: "Case 3" }}}
                    ],
                    default: { console.log: { message: "Default Case" }}
                }
            }
         */
        var expression = this.parse(options.expression);
        for (var i = 0; i < options.cases.length; i++) {
            if (this.parse(options.cases[i].case) === expression) {
                return this._exec(options.cases[i].exec);
            }
        }
        if (options.default) {
            return this._exec(options.default);
        }
    },

    'tryCatch': function(options) {
        var self = this;
        return Promise.resolve(self._exec(options.try)).catch(function() {
            return self._exec(options.catch);
        });
    },

    'run': function(options) {
        if (!options.action) {
            throw new Error('run: missing action');
        }

        return this.parse(options.action);
    },

    'runJS': function(options) {
        if (!options.function) {
            throw new Error('runJS: missing function');
        }

        var func = this.parse(options.function);
        var args = this.parse(options.args);

        return window[func].apply(null, args);
    },

    'assign': function(options) {
        return this.parse(options.value);
    },

    'setGlobal': function(options) {
        var key = this.parse(options.key);
        var value = this.parse(options.value);

        if (typeof key != 'string') {
            throw new Error('setGlobal: key must be a string');
        }

        dmx.global.set(key, value);

        return value;
    },

    'setSession': function(options) {
        var key = this.parse(options.key);
        var value = this.parse(options.value);

        if (typeof key != 'string') {
            throw new Error('setSession: key must be a string');
        }

        sessionStorage.setItem(key, JSON.stringify(value));

        return value;
    },

    'getSession': function(options) {
        var key = this.parse(options.key);

        if (typeof key != 'string') {
            throw new Error('getSession: key must be a string');
        }

        return JSON.parse(sessionStorage.getItem(key));
    },

    'removeSession': function(options) {
        var key = this.parse(options.key);

        if (typeof key != 'string') {
            throw new Error('removeSession: key must be a string');
        }

        sessionStorage.removeItem(key);

        return true;
    },

    'setStorage': function(options) {
        var key = this.parse(options.key);
        var value = this.parse(options.value);

        if (typeof key != 'string') {
            throw new Error('setStorage: key must be a string');
        }

        localStorage.setItem(key, JSON.stringify(value));

        return value;
    },

    'getStorage': function(options) {
        var key = this.parse(options.key);

        if (typeof key != 'string') {
            throw new Error('getStorage: key must be a string');
        }

        return JSON.parse(localStorage.getItem(key));
    },

    'removeStorage': function(options) {
        var key = this.parse(options.key);

        if (typeof key != 'string') {
            throw new Error('removeStorage: key must be a string');
        }

        localStorage.removeItem(key);

        return true;
    },

    'fetch': function(options) {
        var url = this.parse(options.url);
        var method = this.parse(options.method);
        var timeout = this.parse(options.timeout);
        var dataType = this.parse(options.dataType);
        var data = this.parse(options.data);
        var params = this.parse(options.params);
        var headers = this.parse(options.headers);
        var body = null;

        if (typeof url != 'string') {
            throw new Error('fetch: invalid url ' + url);
        }

        if (!['GET', 'POST', 'PUT', 'DELETE'].includes(method)) {
            //throw new Error('fetch: invalid method ' + method);
            method = 'GET';
        }

        if (!['auto', 'json', 'text'].includes(dataType)) {
            dataType = 'auto';
        }

        if (typeof timeout != 'number') {
            timeout = 0;
        }

        if (typeof params == 'object') {
            for (var param in params) {
                url += (url.indexOf('?') != -1 ? '&' : '?')
                    + decodeURIComponent(param) + '=' + decodeURIComponent(params[param]);
            }
        }

        if (method != 'GET') {
            if (dataType == 'text') {
                if (!headers['Content-Type']) {
                    headers['Content-Type'] = 'application/text';
                }
                body = data.toString();
            } else if (dataType == 'json') {
                if (!headers['Content-Type']) {
                    headers['Content-Type'] = 'application/json';
                }
                data = JSON.stringify(data);
            } else {
                if (method == 'POST') {
                    body = new FormData();

                    if (typeof data == 'object' && !Array.isArray(data)) {
                        for (var key in data) {
                            var value = data[key];

                            if (Array.isArray(value)) {
                                if (!/\[\]$/.test(key)) {
                                    key += '[]';
                                }
                                for (var i in value) {
                                    body.append(key, value[i]);
                                }
                            } else {
                                body.set(key, value);
                            }
                        }
                    }
                } else {
                    if (!headers['Content-Type']) {
                        headers['Content-Type'] = 'application/text';
                    }
                    data = data.toString();
                }
            }
        }

        return new Promise(function(resolve, reject) {
            var xhr = new XMLHttpRequest();

            xhr.onerror = reject;
            xhr.onabort = reject;
            xhr.ontimeout = reject;
            xhr.onload = function() {
                var response = xhr.responseText;
                var headers = (function(raw) {
                    var arr = raw.trim().split(/[\r\n]+/);

                    return arr.reduce(function(headers, line) {
                        var parts = line.split(': ');
                        var header = parts.shift();
                        var value = parts.join(': ');

                        headers[header.toLowerCase()] = value;

                        return headers;
                    }, {});
                })(xhr.getAllResponseHeaders());

                if (/^application\/json/.test(headers['content-type'])) {
                    response = JSON.parse(response);
                }

                resolve({
                    status: xhr.status,
                    headers: headers,
                    data: response
                });
            }

            xhr.open(method, url);

            xhr.timeout = timeout * 1000;

            for (var header in headers) {
                xhr.setRequestHeader(header, headers[header]);
            }

            xhr.send(body);
        });
    }

});

// aliasses
dmx.__actions['setValue'] = dmx.__actions['assign'];
dmx.__actions['api'] = dmx.__actions['fetch'];
dmx.__actions['api.send'] = dmx.__actions['fetch'];
dmx.__actions['serverConnect'] = dmx.__actions['fetch'];